<template>
  <div class="page-container">
    <Filter ref="filterRef" :params="params" @search="searchData">
      <template #handle>
        <el-form-item>
          <el-button type="primary" @click="setOtaIsShow">克隆下发</el-button>
        </el-form-item>
      </template>
    </Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage">
      <el-table-column prop="code" label="克隆编号" />
      <el-table-column prop="robotSn" label="目标机器人编码" />
      <el-table-column prop="cabinetSn" label="目标控制柜编码" />
      <el-table-column prop="programName" label="程序名称" />
      <el-table-column prop="programPackageName" label="工艺名称" />
      <el-table-column prop="programFileName" label="工艺程序文件" />
      <el-table-column prop="configCode" label="工艺配置" />
      <el-table-column prop="configList" label="工艺配置文件" />
      <el-table-column label="克隆状态" >
        <template #default="scope">
          <span>{{taskStatus[scope.row.taskStatus]}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createBy" label="创建人" />
      <el-table-column prop="createTime" label="克隆时间" />
    </Table>
  </div>
  <el-dialog
    title="新建克隆下发任务"
    v-model="dialogFormVisible"
    destroy-on-close
    :modal="true"
    :append-to-body="true"
    width="70%"
  >
      <el-form
        ref="detailformRef"
        class="dialog-form"
        :model="detailform"
        :rules="detailformRules"
        label-width="100px"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="desc_title">克隆工艺程序</div>
            <el-form-item label="工艺程序库" prop="programId">
              <el-select v-model="detailform.programId" placeholder="工艺程序库" @change="(val)=>onCloneChange(val,0)" filterable remote clearable>
                <el-option
                  v-for="(item) in cloneProgramList"
                  :key="item.id.toString()"
                  :label="`${item.name}（${item.robotSn}${item.cabinetSn ? '_'+item.cabinetSn: ''}）`"
                  :value="item.id.toString()"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="文件名称" prop="programFileName">
              {{detailform.programFileName}}
            </el-form-item>
            <el-form-item label="文件大小" prop="programFileSize">
              {{detailform.programFileSize}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div class="desc_title">克隆工艺配置</div>
            <el-form-item label="工艺配置库" prop="configId">
              <el-select v-model="detailform.configId" placeholder="工艺程序库"  @change="(val)=>onCloneChange(val,1)"  filterable remote clearable>
                <el-option
                  v-for="(item) in cloneConfigList"
                  :key="item.id.toString()"
                  :label="`${item.name}（${item.robotSn}${item.cabinetSn ? '_'+item.cabinetSn: ''}）`"
                  :value="item.id.toString()"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="configList">
              <el-checkbox-group v-model="detailform.configList">
                <el-checkbox label="控制器" :disabled="!configList.includes('控制器')"></el-checkbox>
                <el-checkbox label="I/O名称" :disabled="!configList.includes('I/O名称')"></el-checkbox>
                <el-checkbox label="扩展I/O" :disabled="!configList.includes('扩展I/O')"></el-checkbox>
                <el-checkbox label="系统变量" :disabled="!configList.includes('系统变量')"></el-checkbox>
                <el-checkbox label="安全区域" :disabled="!configList.includes('安全区域')"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="文件大小" prop="configFileSize">
              {{detailform.configFileSize}}
            </el-form-item>
          </el-col>
        </el-row>
        <div class="desc_title">选择目标机器人
          <el-button size="small" type="primary" @click="addData">新增一行</el-button>
        </div>
        <el-form-item label="" prop="file">
          <el-table :data="detailform.robotList" max-height="360">
            <el-table-column width="80"  label="序号">
              <template #default="scope">{{scope.$index + 1}}</template>
            </el-table-column>
            <el-table-column prop="robotSn" label="机器人编码" width="200">
                <template #default="scope">
                  <el-form-item label="" :prop="`robotList.${scope.$index}.robotSn`" :rules="detailformRules.robotSn" >
                    <el-select v-model="scope.row.robotSn" placeholder="源机器人编码" :remote-method="robotSnQuery"  @change="(val)=>onRobotChange(val,scope.row)" filterable remote clearable>
                      <el-option
                        v-for="(item) in robotSnList"
                        :key="item.robotId"
                        :label="item.robotId"
                        :value="item.robotId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
            </el-table-column>
            <el-table-column prop="cabinetSn" label="控制柜编码" />
            <el-table-column prop="status" label="状态" >
                <template #default="scope">
                {{ statusList[scope.row.status] }}
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-button size="small" type="primary" @click="deleteRobotInfo(scope)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" type="primary" @click="editSubmit"
          >确认</el-button
        >
      </template>
  </el-dialog>
</template>

<script>
import { request } from '@/utils/request'
import { common } from '../../common/mixin'
import Table from '@/components/table/index'
import Filter from '@/components/table/filter'
import { robotStatusMap } from "@/common/constant";
import dayjs from 'dayjs'
import { useRoute } from 'vue-router'
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  components: {
    Table,
    Filter
  },
  mixins: [common],
  data () {
    const route = useRoute()
    const programId = route.query.programId
    const configId = route.query.configId
    const programName = route.query.programName
    const configCode = route.query.configCode
    const createTime = route.query.createTime
    const taskStatus = [
      {
        label: '未开始',
        value: '0'
      },
      {
        label: '等待机器人响应',
        value: '1'
      },
      {
        label: '克隆中',
        value: '2'
      },
      {
        label: '克隆完成',
        value: '3'
      },
      {
        label: '克隆失败',
        value: '4'
      }
    ]
    return {  
      params: [
        {
          key: 'code',
          value: '',
          label: '克隆编号'
        },
        {
          key: 'robotSn',
          value: '',
          label: '机器人编号'
        },
        {
          key: 'programName',
          value: programName,
          label: '工艺名称'
        },
        {
          key: 'configCode',
          value: configCode,
          label: '工艺配置'
        },
        {
          type: 'Select',
          key: 'status',
          value: null,
          label: '克隆状态',
          options: {
            data: taskStatus,
            label: 'label',
            value: 'value'
          }
        },
        {
          type: 'Date',
          key: 'dateRange',
          value: [
            createTime ? dayjs(createTime).format('YYYY-MM-DD') + ' 00:00:00' : dayjs().subtract(7, 'day').format('YYYY-MM-DD') + ' 00:00:00',
            dayjs().format('YYYY-MM-DD') + ' 23:59:59'
          ],
          label: '克隆日期',
          options: {
            type: 'datetimerange',
            format: 'YYYY-MM-DD HH:mm:ss',
            'value-format': 'YYYY-MM-DD HH:mm:ss',
            'range-separator': '至',
            'start-placeholder': '开始日期',
            'end-placeholder': '结束日期',
            'default-time': [
              new Date(2000, 1, 1, 0, 0, 0),
              new Date(2000, 1, 1, 23, 59, 59)
            ]
          },
        }
      ],
      query: {},
      page: 1,
      size: 10,
      total: 0,
      tableDataList: [],
      taskStatus: {0:'未开始',1:'等待机器人响应',2:'克隆中',3:'克隆完成',4:'克隆失败'},
      dialogFormVisible: (programId !== undefined  || configId !== undefined) ? true : false,
      detailform: {
        programId: programId,
        configId: configId,
        programFileSize: '',
        programFileName: "",
        configFileSize: '',
        configFileName: "",
        configList: [],
        robotList: [
          {
            robotSn: '',
            cabinetSn: "",
            status: null
          }
        ]
      },
      configList: [],
      detailformRules:{
        // programId: {
        //   required: true,
        //   message: '请选择工艺程序库',
        //   trigger: 'change'
        // },
        robotSn: {
          required: true,
          message: '请选择机器人编码',
          trigger: 'change'
        },
        // configList: {
        //   required: true,
        //   message: '请选择工艺配置',
        //   trigger: 'change'
        // },
      },
      detailAddformRules:{
      groupName: {
        required: true,
        message: '请输入组别名称',
        trigger: 'change'
      },
      source: {
        required: true,
        message: '请选择来源',
        trigger: 'change'
      },
      serialNo: {
        required: true,
        message: '请选择机器人编码',
        trigger: 'change'
      },
      robotName: {
        required: true,
        message: '请输入机器人名称',
        trigger: 'change'
      },
      power: {
        required: true,
        message: '请输入额定功率',
        trigger: 'change'
      },
    },
      selectedRow: null,
      robotSnList: [],
      cloneProgramList: [],
      cloneConfigList: [],
      checkList: [],
      statusList: robotStatusMap,
    }
  },
  created () {},
  mounted () {
    this.$refs.filterRef?.searchData()
    this.getCloneProgram()
    this.getCloneConfig()
  },
  methods: {
    getData () {
      request('getCloneTask', {
        ...this.query,
        page: this.page,
        size: this.size,
        startTime: this.query.dateRange[0],
        endTime: this.query.dateRange[1]
      })
        .then(data => {
          this.tableDataList = data.records
          this.total = parseInt(data.total)
        })
        .catch(error => {
          console.error(error)
        })
    },
    getDataMap () {
      try {
        request('getArea', {
          parentId: 1
        }).then(data => {
          this.provinceList = data
        })
        request('getRobotStatusMap').then(data => {
          this.robotStatusList = data
        })
        request('getIndustryList').then(data => {
          this.industryList = data
        })
      } catch (error) {
        console.log(error)
      }
    },
    searchData (data) {
      this.query = data
      this.getData()
    },
    updatePage (page, size) {
      this.page = page || 1
      this.size = size || 10
      this.getData()
    },
    handleClick (row) {
      this.$router.push({
        path: '/collect/healthScoreTrend',
        query: { robotSn: row.robotSn }
      })
    },
    setOtaIsShow () {
      this.detailform.programId = ''
      this.detailform.configId = ''
      this.dialogFormVisible = true
      this.detailform.configList = []
      this.detailform.robotList = [
        {
          robotSn: '',
          cabinetSn: "",
          status: null
        }
      ]
      this.detailform.programFileSize = ''
      this.detailform.programFileName = ''
      this.detailform.configFileSize = ''
      this.detailform.configFileName = ''
      this.configList = []
      this.robotSnList = []
    },
    getCloneProgram () {
      request('getCloneProgram', {
        name: '',
        cabinetSn: "",
        endTime: "",
        page: 1,
        robotSn: "",
        size: 1000,
        startTime: "",
        uploadStatus: "3"
      })
        .then(data => {
          this.cloneProgramList = data.records
          this.cloneProgramList.map(item => item.value = item.id)
          this.$route.query.programId && this.onCloneChange(this.$route.query.programId,0)
        })
        .catch(error => {
          console.error(error)
        })
    },
    getCloneConfig () {
      request('getCloneConfig', {
        name: '',
        cabinetSn: "",
        endTime: "",
        page: 1,
        robotSn: "",
        size: 1000,
        startTime: "",
        uploadStatus: "3"
      })
        .then(data => {
          this.cloneConfigList = data.records
          this.$route.query.configId && this.onCloneChange(this.$route.query.configId,1)
        })
        .catch(error => {
          console.error(error)
        })
    },
    onCloneChange (val,type) {
      if(type){
        this.detailform.configFileSize = this.cloneConfigList.filter(item=> item.id == val)[0]?.fileSize
        this.detailform.configList = this.cloneConfigList.filter(item=> item.id == val)[0]?.configList.split(',') || ['控制器','扩展I/O']
        this.configList = this.cloneConfigList.filter(item=> item.id == val)[0]?.configList.split(',') || ['控制器','扩展I/O']
      }else{
        this.detailform.programFileName = this.cloneProgramList.filter(item=> item.id == val)[0]?.fileName
        this.detailform.programFileSize = this.cloneProgramList.filter(item=> item.id == val)[0]?.fileSize
      }
    },
    addData () {
      const data = [{
        status:'',
        cabinetId:'',
        customer:'',
        robotId:'',
        source: '',
        serialNo: ''
      }]
      this.detailform.robotList = [...this.detailform.robotList || [],...data]
    },
    async robotSnQuery (name) {
      try {
        this.robotSnList = []
        const res = await request('getCollectRobot', {robotId: name})
        this.robotSnList = (res.records || []).slice(0, 20)
      } catch (error) {
        console.error(error)
      }
    },
    async editSubmit () {
      if(!(this.detailform.programId || this.detailform.configId)){
        ElMessage({
          showClose: true,
          type: "info",
          message: "工艺程序和工艺配置至少选择一项!"
        })
        return false
      }
      if(this.detailform.configId && this.detailform.configList.length == 0){
        ElMessage({
          showClose: true,
          type: "info",
          message: "工艺配置至少选择一项!"
        })
        return false
      }
      this.$refs.detailformRef && await this.$refs.detailformRef.validate()
      request('createCloneTask', this.detailform)
        .then(data => {
          this.dialogFormVisible = false
          this.getData();
        })
        .catch(error => {
          console.error(error)
        })
    },
    deleteRobotInfo (scope){
      this.detailform.robotList.splice(scope.$index,1)
    },
    onRobotChange (val,item) {
      const currtRobot = this.robotSnList.filter(it=> it.robotId == val)[0]
      item.cabinetSn = currtRobot.cabinetId
      item.status = currtRobot.status
    },
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.desc_title{
  position: relative;
  padding-left: 12px;
  margin-bottom: 20px;
  font-size: 18px;
  color: #1677ff;
}
.desc_title::before{
  position: absolute;
  content: ' ';
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 16px;
  background-color: #1677ff;
}
.progress-text {
  font-size: 12px;
}
</style>
<template>
  <div class="page-container">
    <Filter ref="filterRef" :params="params" @search="searchData">
      <template #handle>
        <el-form-item>
          <el-button type="primary" @click="addConfigTable(0)">本地上传</el-button>
          <el-button type="primary" @click="addConfigTable(1)">远程克隆</el-button>
        </el-form-item>
      </template>
    </Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage">
      <el-table-column prop="name" label="工艺配置" />
      <el-table-column prop="source" label="文件来源" >
        <template #default="scope">
          <span>{{scope.row.source ? "远程":"本地"}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="robotSn" label="源机器人编码" />
      <el-table-column prop="cabinetSn" label="源控制柜编码" />
      <el-table-column prop="configList" label="文件名称" />
      <el-table-column prop="fileSize" label="文件大小" />
      <el-table-column label="上传状态" >
        <template #default="scope">
          <span>{{uploadStatus[scope.row.uploadStatus]}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="isDisable" label="是否禁用" >
        <template #default="scope">
          <el-switch
            @change="enableChange(scope.row)"
            v-model="scope.row.isDisable"
            active-text=""
            inactive-text="">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="createBy" label="创建人" />
      <el-table-column prop="createTime" label="上传时间" />
      <el-table-column prop="robotSn" label="操作" >
        <template #default="{ row }">
          <el-button
            v-if="!row.hasCloneRecord && row.isDisable"
            @click="handleOperate(row,0)"
            type="text"
            size="small"
            >编辑</el-button
          >
          <el-button
            v-if="row.isDisable && row.uploadStatus == 3"
            @click="handleOperate(row,1)"
            type="text"
            size="small"
            >克隆</el-button
          >
          <el-button
            v-if="row.hasCloneRecord"
            @click="handleOperate(row,2)"
            type="text"
            size="small"
            >克隆记录</el-button
          >
          <el-button
            v-if="!row.hasCloneRecord && !row.isDisable"
            @click="handleOperate(row,3)"
            type="text"
            size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </Table>
  </div>
  <el-dialog
    :title="detailform.source?'远程克隆工艺配置库': '本地上传工艺配置库'"
    v-model="dialogFormVisible"
    destroy-on-close
    :modal="true"
    :append-to-body="true"
  >
      <el-form
        ref="detailformRef"
        class="dialog-form"
        :model="detailform"
        :rules="detailformRules"
        label-width="100px"
      >
        <el-form-item label="工艺配置" prop="name">
          <el-input
            v-model="detailform.name"
            placeholder="工艺配置"
            clearable
          />
        </el-form-item>
        <div class="desc_title">源机器人</div>
        <el-table
          :data="tableData1"
          tooltip-effect="dark"
          row-key="id"
        >
          <el-table-column label="机器人编码">
            <template #default="scope">
              <el-form-item label="机器人编码" prop="robotSn">
              <el-select v-model="detailform.robotSn" placeholder="机器人编码" :remote-method="robotSnQuery" @change="(val)=>onRobotChange(val)" filterable remote clearable>
                <el-option
                  v-for="(item) in robotSnList"
                  :key="item.robotId"
                  :label="item.robotId"
                  :value="item.robotId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="cabinetSn" label="控制柜编码">
            {{ detailform.cabinetSn }}
          </el-table-column>
          <el-table-column prop="robotStatus" label="状态">
            {{ statusList[detailform.robotStatus] }}
          </el-table-column>
        </el-table>
        <div v-if="!detailform.source" class="desc_title" style="margin-top: 20px;">上传工艺配置</div>
        <el-form-item v-if="!detailform.source" label-width="0px" label="" prop="file">
          <el-table
            ref="multipleTable"
            :data="configList"
            tooltip-effect="dark"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column width="80"  label="序号">
              <template #default="scope">{{scope.$index + 1}}</template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="配置文件"
              >
            </el-table-column>
            <el-table-column
              prop="file"
              label="配置文件"
              >
              <template #default="scope">
                <el-form-item label="" prop="file">      
                <Upload
                  :file-list="fileList"
                  :limit="1"
                  :auto-upload="false"
                  :on-change="(val)=>onFileChange(val,scope)"
                  :on-remove="(val)=>onFileRemove(val,scope.row)"
                >
                  <el-button :disabled="!!scope.row.file" type="text">点击上传文件</el-button>
                  <template #tip>
                    <div v-if="!scope.row.file" class="el-upload__tip">文件名称：{{ scope.row.fileName }}</div>
                  </template>
                </Upload>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
         
        </el-form-item>
        <div v-if="detailform.source" class="desc_title" style="margin-top: 20px;">选择工艺配置</div>
        <el-form-item v-if="detailform.source" label="" prop="configList">
          <el-checkbox-group v-model="detailform.configList">
            <el-checkbox label="控制器"></el-checkbox>
            <el-checkbox label="I/O名称"></el-checkbox>
            <el-checkbox label="扩展I/O"></el-checkbox>
            <el-checkbox label="系统变量"></el-checkbox>
            <el-checkbox label="安全区域"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" type="primary" @click="editSubmit"
          >确认</el-button
        >
      </template>
  </el-dialog>
</template>

<script>
import { request } from '@/utils/request'
import { common } from '../../common/mixin'
import Table from '@/components/table/index'
import Filter from '@/components/table/filter'
import dayjs from 'dayjs'
import { ElMessage, ElMessageBox } from "element-plus";
import Upload from '@/components/upload'
import { reqCloneConfig } from "@/api/robot"
import { robotStatusMap } from "@/common/constant";
export default {
  components: {
    Table,
    Filter,
    Upload
  },
  mixins: [common],
  data () {
    const uploadStatus = [
      {
        label: '未开始',
        value: '0'
      },
      {
        label: '等待机器人响应',
        value: '1'
      },
      {
        label: '上传中',
        value: '2'
      },
      {
        label: '上传完成',
        value: '3'
      },
      {
        label: '上传失败',
        value: '4'
      }
    ]
    const configTypeList = [
      {
        label: '控制器',
        value: '控制器'
      },
      {
        label: 'I/O名称',
        value: 'I/O名称'
      },
      {
        label: '扩展I/O',
        value: '扩展I/O'
      },
      {
        label: '系统变量',
        value: '系统变量'
      },
      {
        label: '安全区域',
        value: '安全区域'
      }
    ]
    return {
      params: [
        {
          key: 'name',
          value: '',
          label: '工艺配置'
        },
        {
          key: 'robotSn',
          value: '',
          label: '机器人编号'
        },
        {
          key: 'cabinetSn',
          value: '',
          label: '控制柜编号'
        },
        {
          type: 'Select',
          key: 'configTypeList',
          value: null,
          label: '配置文件',
          options: {
            data: configTypeList,
            label: 'label',
            value: 'value'
          },
          attrs: {
            multiple: true
          }
        },
        {
          type: 'Select',
          key: 'uploadStatus',
          value: null,
          label: '上传状态',
          options: {
            data: uploadStatus,
            label: 'label',
            value: 'value'
          }
        },
        {
          type: 'Date',
          key: 'dateRange',
          value: [
            dayjs().subtract(7, 'day').format('YYYY-MM-DD') + ' 00:00:00',
            dayjs().format('YYYY-MM-DD') + ' 23:59:59'
          ],
          label: '上传日期',
          options: {
            type: 'datetimerange',
            format: 'YYYY-MM-DD HH:mm:ss',
            'value-format': 'YYYY-MM-DD HH:mm:ss',
            'range-separator': '至',
            'start-placeholder': '开始日期',
            'end-placeholder': '结束日期',
            'default-time': [
              new Date(2000, 1, 1, 0, 0, 0),
              new Date(2000, 1, 1, 23, 59, 59)
            ]
          }
        }
      ],
      query: {},
      page: 1,
      size: 10,
      total: 0,
      tableDataList: [],
      dialogFormVisible: false,
      detailform: {
        name: '',
        robotSn: '',
        cabinetSn: '',
        file: '',
        configList: [],
        configList1: [],
        source: 0,
        taskType: 1,
      },
      configList: [
        {
          name: '控制器',
          file: null,
          type: 'ini',
          fileName: 'usersettings.ini'
        },
        {
          name: 'I/O名称',
          file: null,
          type: 'ini',
          fileName: 'aliasname.ini'
        }, 
        {
          name: '扩展I/O',
          file: null,
          type: 'xml',
          fileName: 'extiosetup.xml'
        },
        {
          name: '系统变量',
          file: null,
          type: 'var',
          fileName: 'jaka_user.var'
        }, 
        {
          name: '安全区域',
          file: null,
          type: 'xml',
          fileName: 'safezone.xml'
        }
      ],
      tableData1: [{programName: 'fdasf'},],
      detailformRules:{
        name: {
          required: true,
          message: '请输入工艺配置',
          trigger: 'change'
        },
        robotSn: {
          required: true,
          message: '请选择机器人编号',
          trigger: 'change'
        },
        selectedRow: {
          required: true,
          message: '请选择工艺程序',
          trigger: 'change'
        },
        configList: {
          required: true,
          message: '请选择工艺配置',
          trigger: 'change'
        },
      },
      fileList: [],
      uploadStatus: {0:'未开始',1:'等待机器人响应',2:'上传中',3:'上传完成',4:'上传失败'},
      selectedRow: null,
      robotSnList: [],
      statusList: robotStatusMap,
    }
  },
  created () {},
  mounted () {
    this.$refs.filterRef?.searchData()
  },
  methods: {
    getData () {
      request('getCloneConfig', {
        ...this.query,
        page: this.page,
        size: this.size,
        startTime: this.query.dateRange[0],
        endTime: this.query.dateRange[1]
      })
        .then(data => {
          this.tableDataList = data.records
          this.total = parseInt(data.total)
        })
        .catch(error => {
          console.error(error)
        })
    },
    getDataMap () {
      try {
        request('getArea', {
          parentId: 1
        }).then(data => {
          this.provinceList = data
        })
        request('getRobotStatusMap').then(data => {
          this.robotStatusList = data
        })
        request('getIndustryList').then(data => {
          this.industryList = data
        })
      } catch (error) {
        console.log(error)
      }
    },
    searchData (data) {
      this.query = data
      this.getData()
    },
    updatePage (page, size) {
      this.page = page || 1
      this.size = size || 10
      this.getData()
    },
    handleClick (row) {
      this.$router.push({
        path: '/collect/healthScoreTrend',
        query: { robotSn: row.robotSn }
      })
    },
    addConfigTable (type) {
      this.detailform.source = type
      this.dialogFormVisible = true
      this.detailform.configList = []
      this.detailform.robotSn = ''
      this.detailform.cabinetSn = ''
      this.detailform.file = ''
      this.detailform.configList1 = []
      this.detailform.name = ''
      this.detailform.id = null
      this.selectedRow = null
      this.robotSnList = []
      this.fileList = []
      this.detailform.robotSnList = []
      this.configList = [
      {
          name: '控制器',
          file: null,
          type: 'ini',
          fileName: 'usersettings.ini'
        },
        {
          name: 'I/O名称',
          file: null,
          type: 'ini',
          fileName: 'aliasname.ini'
        }, 
        {
          name: '扩展I/O',
          file: null,
          type: 'xml',
          fileName: 'extiosetup.xml'
        },
        {
          name: '系统变量',
          file: null,
          type: 'var',
          fileName: 'jaka_user.var'
        }, 
        {
          name: '安全区域',
          file: null,
          type: 'xml',
          fileName: 'safezone.xml'
        }
      ]
    },
    enableChange (val) {
      request("cloneConfigDisable", {
        isDisable: val.isDisable,
        id: val.id,
      })
        .then((data) => {
          this.getData();
        })
        .catch((error) => {
          this.getData();
          console.error(error);
        });
    },
    async editSubmit () {
      this.$refs.detailformRef && await this.$refs.detailformRef.validate()
      const data = new FormData()
      Object.keys(this.detailform).forEach((key) => {
        if(key !== 'configList'){
          this.detailform[key] && data.append(key, this.detailform[key])
        }
      })
      if(this.detailform.source == 1){
        request('createUploadTask', this.detailform)
        .then(data => {
          this.dialogFormVisible = false
          this.getData();
        })
        .catch(error => {
          console.error(error)
        })
      }else{
        if(this.selectedRow == null){
          ElMessage({
            showClose: true,
            type: "info",
            message: "至少选择一个工艺配置!"
          })
          return false
        }
        this.selectedRow.map(item=>{data.append('files', item?.file);})
        data.append("configList", this.selectedRow.map(item=>item.name).join(','))
        request('cloneConfigUpload', data)
          .then(data => {
            this.dialogFormVisible = false
            this.getData();
          })
          .catch(error => {
            console.error(error)
          })
        }
    },
    async handleOperate (row,type) {
      switch (type) {
        case 0: 
          this.detailform.id = row.id
          this.detailform.name = row.name
          this.detailform.robotSn = row.robotSn
          this.detailform.cabinetSn = row.cabinetSn
          this.detailform.file = row.file
          this.detailform.source = row.source
          this.detailform.configList = row.configList.split(',')
          this.dialogFormVisible = true
          this.robotSnQuery(row.robotSn)
          this.$nextTick(() => {
            this.detailform.configList.map(item=>{
              this.$refs.multipleTable.toggleRowSelection(this.configList.filter(it=> item == it.name)[0])
            })
          });
          this.configList = [{
          name: '控制器',
          file: null,
          type: 'ini',
          fileName: 'usersettings.ini'
        },
        {
          name: 'I/O名称',
          file: null,
          type: 'ini',
          fileName: 'aliasname.ini'
        }, 
        {
          name: '扩展I/O',
          file: null,
          type: 'xml',
          fileName: 'extiosetup.xml'
        },
        {
          name: '系统变量',
          file: null,
          type: 'var',
          fileName: 'jaka_user.var'
        }, 
        {
          name: '安全区域',
          file: null,
          type: 'xml',
          fileName: 'safezone.xml'
        }
      ]
          break;
        case 1:  
          this.$router.push({
            path: '/process/cloningRecord',
            query: { configId: row.id }
          });
         break;
        case 2:     
          this.$router.push({
            path: '/process/cloningRecord',
            query: { configCode: row.code, createTime: row.createTime }
          });
          break;
        case 3: 
          try {
            await ElMessageBox.confirm(
            '确定删除？',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
          )
          await reqCloneConfig(row.id)
          ElMessage({
            showClose: true,
            type: "success",
            message: "删除成功!"
          })
          this.getData()
      } catch (error) {
        console.error(error)
      }
        break;
      }
  
    },
    onFileChange (file,it) {
        let res = false
        for (let item of ['ini', 'xml', 'var']) {
          if (file.name.includes(item)) {
            res = true
            break
          }
        }
        if (!res) {
          this.fileList = []
          ElMessage({
            showClose: true,
            type: "warning",
            message: "只能上传ini、xml、var格式的文件"
          })
          return
        }
        it.row.file = file.raw
        it.row.raw = file.raw
        // this.configList[it.$index].raw = file.raw
    },
    onFileRemove (val,item) {
      item.file = undefined
      item.raw = undefined
    },
    async robotSnQuery (name) {
      try {
        this.robotSnList = []
        const res = await request('getCollectRobot', {
          robotId: name
        })
        this.robotSnList = (res.records || []).slice(0, 20)
      } catch (error) {
        console.error(error)
      }
    },
    onRobotChange (val) {
      const currtRobot = this.robotSnList.filter(item=> item.robotId == val)[0]
      this.detailform.cabinetSn = currtRobot.cabinetId
      this.detailform.robotStatus = currtRobot.status
    },
    handleSelectionChange(val) {
      this.selectedRow = val;
      console.log(this.selectedRow)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.progress-text {
  font-size: 12px;
}
.desc_title{
  position: relative;
  padding-left: 12px;
  margin-bottom: 20px;
  font-size: 18px;
  color: #1677ff;
}
.desc_title::before{
  position: absolute;
  content: ' ';
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 16px;
  background-color: #1677ff;
}
</style>


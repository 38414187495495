<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData" :add="true" @click="addOTA">
    </Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage" select @select="handleSelect">
      <el-table-column prop="name" label="OTA大屏名称">
        <template #default="scope">
          <el-badge v-if="scope.row.unbindRobotCount !==0" :value="scope.row.unbindRobotCount" type="error" :max="99" class="item">
            <div style="padding: 4px;"> {{scope.row.name}}</div>
          </el-badge>
          <div v-else style="padding: 4px;"> {{scope.row.name}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="groupCount" label="分组数量"/>
      <el-table-column prop="robotCount" label="机器人数量"/>
      <el-table-column label="所属公司" prop="createCustomer"></el-table-column>
      <el-table-column label="是否禁用">
        <template #default="scope">
          <el-switch
            @change="enableChange(scope.row)"
            v-model="scope.row.enable"
            active-text=""
            inactive-text="">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="createByStr" label="创建人"/>
      <el-table-column prop="createTime" label="创建时间"/>
      <el-table-column prop="updateByStr" label="更新人"/>
      <el-table-column prop="updateTime" label="更新日期"/>
      <el-table-column label="操作" width="240" fixed="right">
        <template #default="scope">
          <el-button autofocus="true" type="primary" plain size="mini" @click="handleDelete(scope.row)"
            >删除</el-button
          >
          <el-button autofocus="true" type="primary" plain size="mini" @click="handleEdit(scope.row)"
            >编辑</el-button
          >
          <el-button autofocus="true" :disabled="!scope.row.enable" type="primary" plain size="mini" @click="handleClick(scope.row)"
            >查看大屏</el-button
          >
        </template>
      </el-table-column>
    </Table>

    <el-dialog
      :title="edit?'编辑OTA大屏': '新增OTA大屏'"
      v-model="dialogFormVisible"
      destroy-on-close
      :modal="true"
      :append-to-body="true"
       width="70%"
    >
      <el-form
        ref="detailformRef"
        class="dialog-form"
        :model="detailform"
        :rules="detailformRules"
        label-width="100px"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="大屏名称" prop="name">
              <el-input
                v-model="detailform.name"
                placeholder="大屏名称"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="edit" label="边端ID" prop="id">
              <el-input
                disabled="true"
                v-model="detailform.id"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="上传Logo" prop="logoUrl">
          <el-upload
            :file-list="imageList"
            action="#"
            :limit="1"
            :auto-upload="false"
            name="image"
            list-type="picture-card"
            :on-change="onImageChange"
            :on-remove="onImageRemove"
            :on-preview="onImagePreview"
          >
            <i class="el-icon-plus"></i>
            <template #tip>
              <div class="el-upload__tip">
                数量1张，大小不超过 500KB，
                <span style="color:red;">图片尺寸：660*360</span>
              </div>
            </template>
          </el-upload>
        </el-form-item>
        <el-form-item label="自动轮播时长" prop="scrollInterval">
          <el-input-number style="width:40%" placeholder="自动轮播时长，0或空表示不轮播" v-model="detailform.scrollInterval" controls-position="right" clearable :min="0" :max="20"></el-input-number>
        </el-form-item>
        <el-form-item label="使用描述" prop="description">
          <el-input
            :rows="2"
            v-model="detailform.description"
            placeholder="使用描述"
            clearable
            type="textarea"
          />
        </el-form-item>
      </el-form>
      <div class='title'>
        <div >机器人配置</div>
        <el-button size="small" type="primary" @click="addGroup">添加组别</el-button>
      </div>
      <el-table :data="detailform.groupList" max-height="400" min-height="300">
        <el-table-column type="expand">
          <template #default="scope">
            <el-table :data="scope.row.robotList">
              <el-table-column label="序号" type="index" width="50"></el-table-column>
              <el-table-column prop="serialNo" label="机器人编码" />
              <el-table-column prop="robotName" label="机器人名称" />
              <el-table-column prop="power" label="额定功率" />
              <el-table-column prop="cabinetId" label="控制柜编码" />
              <el-table-column prop="customer" label="客户名称" />
              <el-table-column prop="source" label="来源" />
              <el-table-column prop="status" label="状态" >
                <template #default="scope">
                  {{ statusList[scope.row.status] }}
                </template>
              </el-table-column>
              <el-table-column prop="updateTime" label="更新时间" />
            </el-table>
          </template>
           
        </el-table-column>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="code" label="分组编号" />
        <el-table-column prop="name" label="分组名称" />
        <el-table-column prop="robotCount" label="机器人数量">
          <template #default="scope">
            {{ scope.row.robotList?.length }}
          </template>
        </el-table-column>
        <el-table-column label="机器人序列号">
          <template #default="scope">
            {{ scope.row.robotList !== undefined ? scope.row.robotList.map(it=> it.serialNo).toString() : '' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template #default="scope">
            <el-button size="small" type="text" @click="editGroup(scope.row)">编辑组别</el-button>
            <el-button size="small" type="text" @click="deleteRobotInfo(scope, 0)">删除组别</el-button>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <el-button size="small" type="primary" @click="editSubmit"
          >确认</el-button
        >
      </template>
    </el-dialog>

    <el-dialog
      :title="robotEdit ? '编辑组别': '添加组别'"
      v-model="dialogAddFormVisible"
      destroy-on-close
      :modal="true"
      :append-to-body="true"
      width="70%"
    >
      <el-form
        ref="detailAddformRef"
        class="dialog-form"
        :model="detailform"
        :rules="detailAddformRules"
      >
        <el-form-item label="组别名称" prop="groupName">
          <el-input
            v-model="detailform.groupName"
            placeholder="组别名称"
            clearable
          />
        </el-form-item>
      <!-- </el-form> -->
      <el-button size="small" type="primary" @click="addData">新增一行</el-button>
      <el-table :data="detailform.robotList" max-height="480">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column v-if="edit" prop="source" label="来源" >
          <template #default="scope">
            <el-form-item label="" :prop="`robotList.${scope.$index}.source`" :rules="detailAddformRules.source">
              <el-select v-model="scope.row.source"  clearable placeholder="请选择">
                <el-option key="发现" label="发现" value="发现"></el-option>
                <el-option key="绑定" label="绑定" value="绑定"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="serialNo" label="机器人编码" width="200">
            <template #default="scope">
              <el-form-item label="" :prop="`robotList.${scope.$index}.serialNo`" :rules="detailAddformRules.serialNo">
                <el-select v-model="scope.row.serialNo" clearable @change="(val)=>onRobotChange(val,scope.row)" filterable placeholder="请选择">
                  <el-option
                  :disabled="[...currRobotList,...detailform.robotList.map(item => item.serialNo)].includes(item.robotId) "
                  v-for="item in edit ? scope.row.source == '绑定'? optionsBind : optionsFind : optionsBind"
                  :key="item.robotId"
                  :label="item.robotId"
                  :value="item.robotId">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
        </el-table-column>
        <el-table-column prop="robotName" label="机器人名称" >
          <template #default="scope">
            <el-form-item label="" :prop="`robotList.${scope.$index}.robotName`" :rules="detailAddformRules.robotName">   
              <el-input v-model="scope.row.robotName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="额定功率" prop="power">
          <template #default="scope">
            <el-form-item label="" :prop="`robotList.${scope.$index}.power`" :rules="detailAddformRules.power">         
              <el-input v-model="scope.row.power" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="cabinetId" label="控制柜编码" />
        <el-table-column prop="customer" label="客户名称" />
        <el-table-column prop="status" label="状态" >
            <template #default="scope">
            {{ statusList[scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button size="small" type="primary" @click="deleteRobotInfo(scope, 1)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
      <template #footer>
        <el-button size="small" type="primary" @click="addGroupInfo"
          >确认</el-button
        >
      </template>
    </el-dialog>
    <el-image-viewer
      v-if="viewerList?.length"
      :url-list="viewerList"
      :initial-index="viewerIndex"
      :infinite="false"
      :z-index="3000"
      @close="viewerList=[]"
    ></el-image-viewer>
  </div>
  
</template>

<script>
import { request } from "@/utils/request";
import { common } from "../../common/mixin";
// import { ElMessage } from "element-plus";
import { robotStatusMap } from "@/common/constant";
import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
import { otaUpgradeMap } from "@/common/constant"
import { reqGetDetail } from "@/api/robot"
import { reactive, toRefs } from "vue";
import { cloneDeep } from "lodash-es";
import { reqDeleteScreen } from "@/api/robot"
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  components: {
    Table,
    Filter,
  },
  mixins: [common],
  setup() {
    const state = reactive({
      query: {
        name: null,
        endTime: null,
        startTime: null,
        robotSn: null,
        customer: null,
        otaUpgradeTimeRange:[],
      },
      page: 1,
      size: 10,
      total: 0,
      edit: false,
      robotEdit: false,
      tableDataList: [],
      statusList: robotStatusMap,
      options: [
        {
          id: true,
          name: "是",
        },
        {
          id: false,
          name: "否",
        },
      ],
      optionsFind: [],
      optionsBind: [],
      dialogFormVisible: false,
      dialogAddFormVisible: false,
      detailformRef: null,
      detailAddformRef: null,
      detailform: {
        id:'',
        name: '',
        description: '',
        groupName: '',
        groupId: '',
        logoUrl: '',
        scrollInterval: '',
        groupList: [],
        robotList: [{source: 1}],
      },
      groupList: [],
      robotList: [],
      currRobotList: [],
      otaNewVer: '',
      batchDataList: [],
      otaUpgradeMap,
      swimlaneRef: null,
      token: window.localStorage.getItem('token'),
      imageList: [],
      viewerList: [],
      viewerIndex: 0
    })
    const detailformRules = reactive({
      name: {
        required: true,
        message: '请输入大屏名称',
        trigger: 'change'
      },
    })
    const detailAddformRules = reactive({
      groupName: {
        required: true,
        message: '请输入组别名称',
        trigger: 'change'
      },
      source: {
        required: true,
        message: '请选择来源',
        trigger: 'change'
      },
      serialNo: {
        required: true,
        message: '请选择机器人编码',
        trigger: 'change'
      },
      robotName: {
        required: true,
        message: '请输入机器人名称',
        trigger: 'change'
      },
      power: {
        required: true,
        message: '请输入额定功率',
        trigger: 'change'
      },
    })
    const params = reactive([
      {
        key: "name",
        value: "",
        label: "大屏名称",
      },
      {
        key: "robotSn",
        value: "",
        label: "机器人编码",
      },
      {
        key: "customer",
        value: "",
        label: "所属公司",
      },
      {
        type: "Date",
        key: "otaUpgradeTimeRange",
        value: [],
        label: "更新日期",
        options: {
          "type": "datetimerange",
          "format": "YYYY-MM-DD HH:mm:ss",
          "value-format": "YYYY-MM-DD HH:mm:ss",
          "range-separator": "至",
          "start-placeholder": "开始日期",
          "end-placeholder": "结束日期",
          "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]
        }
      },
    ])
   
    const onImageChange = (file) => {
      const formData = new FormData();
      formData.append('file', file.raw)
      console.log()
      request("uploadLogo", formData)
        .then((data) => {
          state.detailform.logoUrl = data.filePath
        })
        .catch((error) => {
          console.error(error);
        });
    }
    const onImageRemove = () => {
      state.detailform.logoUrl = ''
    }
    const onImagePreview = (item) => {
      console.log('onImagePreview', item)
      if (item) {
        state.viewerList = state.imageList.map(item => item.url)
        state.viewerIndex = state.viewerList.indexOf(item.url)
      }
    }

    const getData = () => {
      request("getScreenPageList", {
        ...state.query,
        page: state.page,
        size: state.size,
        startTime: state.query?.otaUpgradeTimeRange[0],
        endTime: state.query?.otaUpgradeTimeRange[1],
      })
        .then((data) => {
          state.tableDataList = data.records;
          state.total = data.total;
        })
        .catch((error) => {
          console.error(error);
        });
    }
    const APP_ENV = process.env.APP_ENV
    const handleClick = (row) => {
      const url = APP_ENV == "dev" ? "https://ota.uat.jaka.com" : `${window.location.protocol}//${window.location.hostname}`
      window.open(`${url}/screen/login?id=${row.id}&token=${state.token}`)
    }
    const handleDelete = async(item) =>{
      try {
        await ElMessageBox.confirm(
          '确定删除？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
        ElMessage({
          showClose: true,
          type: "success",
          message: "操作成功!"
        })
        await reqDeleteScreen(item.id)
        getData()
      } catch (error) {
        console.error(error)
      }
    }
    const handleEdit = async(item) =>{
      state.edit = true
      try {
        const res = await reqGetDetail(item.id)
        state.dialogFormVisible = true
        state.detailform = res
        state.imageList = res.logoUrl ? [{url: res.logoUrl }] : []
      } catch (error) {
        console.error(error)
      }
    }
    const enableChange = async(val) => {
      request("enableScreen", {
        enable: val.enable,
        screenId: val.id,
      })
        .then((data) => {
          getData();
        })
        .catch((error) => {
          console.error(error);
        });
    }
    const searchData = (data) => {
      state.query = data;
      getData();
    }
    const updatePage = (page, size) => {
      state.page = page || 1;
      state.size = size || 10;
      getData();
    }
    const formatStatus = (row) => {
      return robotStatusMap[row.status];
    }
    const handleSelect = (data) => {
      state.batchDataList = data.map((item)=>{
        return item.robotId
      })
    }
    const addOTA = async () => {
      state.edit = false
      state.dialogFormVisible = true
      state.imageList = []
      state.detailform = {
        edgeId:'',
        name: '',
        description: '',
        groupName: '',
        groupList: [],
      }
    }
    const upgradeOTA = async () => {
      state.dialogFormVisible = true
    }

   const onRobotChange = (value,item) => {
    const data = state.optionsBind.filter(it => it.robotId == value)[0]
    item.status = data.status
    item.cabinetId = data.cabinetId
    item.customer = data.customer
    item.id = data.id
    item.serialNo = data.robotId
  }

    const getFindRobotData = async (screenEdgeId) => {
      try {
        const res = await request('getDigScreenRobotList', {
          // size: state.size,
          screenEdgeId: screenEdgeId,
        })
        state.optionsFind = res || []
      } catch (error) {
        console.error(error)
      }
    }

    const getBindRobotData = async () => {
      try {
        const res = await request('getDigScreenRobotList', {
          // size: state.size,
        })
        state.optionsBind = res || []
      } catch (error) {
        console.error(error)
      }
    }

    const addGroup = ()=>{
      state.dialogAddFormVisible = true
      getBindRobotData()
      getCurrRobotList(-1)
      state.edit && getFindRobotData(state.detailform.id)
      state.robotEdit = false
      state.detailform.robotList = []
      state.detailform.groupName = ''
    }
    const editGroup = (row)=>{
      state.detailform.robotList = cloneDeep(row.robotList)
      state.dialogAddFormVisible = true
      getCurrRobotList(row.id)
      state.detailform.groupName = row.name
      state.detailform.groupId = row.id
      state.edit && getFindRobotData(state.detailform.id)
      state.robotEdit = true
      getBindRobotData()
    }

    const getCurrRobotList = (id) =>{
      state.currRobotList = []
      state.detailform.groupList && state.detailform.groupList.map(item=>{
        if(id !== item.id){
          item.robotList && item.robotList.map(it=>{
            state.currRobotList.push(it.serialNo)
          })
        }
      })
      state.currRobotList = [...new Set(state.currRobotList)]
    }

    const addData = ()=>{
      const data = [{
        status:'',
        cabinetId:'',
        customer:'',
        robotId:'',
        source: ''
      }]
      state.detailform.robotList = [...state.detailform.robotList || [],...data]
    }

    const deleteRobotInfo  = (scope, type)=>{
      type ? state.detailform.robotList.splice(scope.$index,1) : state.detailform.groupList.splice(scope.$index,1)
    }

    const addGroupInfo = async()=>{
      state.detailAddformRef && (await state.detailAddformRef.validate())
      state.detailform.robotList.map(item=>{
        item.robotId = item.id
        item.source = state.edit ? item.source : '绑定'
      })
      const data = [{
        name: state.detailform.groupName,
        robotList: state.detailform.robotList
      }]
      if(state.robotEdit){
        state.detailform.groupList.map(item=>{
          if(item.id == state.detailform.groupId){
            item.name = state.detailform.groupName,
            item.robotList = state.detailform.robotList
          }
        })
      }else{
        state.detailform.groupList = [...state.detailform.groupList || [],...data,]
      }
      state.dialogAddFormVisible = false
    }

    const editSubmit = async()=>{
      state.detailformRef && (await state.detailformRef.validate())
      // try {
      //   await reqAddOrUpdateScreen(state.detailform)
      //   state.dialogFormVisible = false
      // } catch (error) {
      //   console.error(error)
      // }
      try {
        await request('addOrUpdateScreen', state.detailform)
        state.dialogFormVisible = false
        getData()
      } catch (error) {
        console.error(error)
      }
    }
    getData()
    return {
      ...toRefs(state),
      detailformRules,
      detailAddformRules,
      params,
      handleClick,
      handleDelete,
      handleEdit,
      searchData,
      updatePage,
      formatStatus,
      handleSelect,
      addOTA,
      upgradeOTA,
      addGroup,
      editGroup,
      addGroupInfo,
      editSubmit,
      addData,
      deleteRobotInfo,
      onRobotChange,
      enableChange,
      onImageChange,
      onImageRemove,
      onImagePreview
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.item {
  margin-top: 10px;
  margin-right: 40px;
}
.title {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
}
// ::deep .el-table td, .el-table th.is-leaf {
//     border-bottom: var(--el-table-border);
//     background: #9e9e9e14;
//     text-align: center;
// }
</style>
<template>
  <div class="page-container">
    <Filter ref="filterRef" :params="params" @search="searchData">
      <template #handle>
        <el-form-item>
          <el-button type="primary" @click="addProgramLib(0)">本地上传</el-button>
          <el-button type="primary" @click="addProgramLib(1)">远程克隆</el-button>
        </el-form-item>
      </template>
    </Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage">
      <el-table-column prop="name" label="工艺名称" />
      <el-table-column prop="source" label="文件来源" >
        <template #default="scope">
          <span>{{scope.row.source ? "远程":"本地"}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="robotSn" label="源机器人编码" />
      <el-table-column prop="cabinetSn" label="源控制柜编码" />
      <el-table-column prop="programName" label="程序名称" />
      <el-table-column prop="fileName" label="文件名称" />
      <el-table-column prop="fileSize" label="文件大小" />
      <el-table-column label="上传状态" >
        <template #default="scope">
          <span>{{uploadStatus[scope.row.uploadStatus]}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="isDisable" label="是否禁用" >
        <template #default="scope">
          <el-switch
            @change="enableChange(scope.row)"
            v-model="scope.row.isDisable"
            active-text=""
            inactive-text="">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="createBy" label="创建人" />
      <el-table-column prop="createTime" label="上传时间" />
      <el-table-column prop="robotSn" label="操作" >
        <template #default="{ row }">
          <el-button
            v-if="!row.hasCloneRecord && row.isDisable"
            @click="handleOperate(row,0)"
            type="text"
            size="small"
            >编辑</el-button
          >
          <el-button
            v-if="row.isDisable && row.uploadStatus == 3"
            @click="handleOperate(row,1)"
            type="text"
            size="small"
            >克隆</el-button
          >
          <el-button
            v-if="row.hasCloneRecord"
            @click="handleOperate(row,2)"
            type="text"
            size="small"
            >克隆记录</el-button
          >
          <el-button
            v-if="!row.hasCloneRecord && !row.isDisable"
            @click="handleOperate(row,3)"
            type="text"
            size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </Table>
  </div>
  <el-dialog
    :title="detailform.source?'远程克隆工艺程序库': '本地上传工艺程序库'"
    v-model="dialogFormVisible"
    destroy-on-close
    :modal="true"
    :append-to-body="true"
  >
      <el-form
        ref="detailformRef"
        class="dialog-form"
        :model="detailform"
        :rules="detailformRules"
        label-width="100px"
      >
        <el-form-item label="工艺名称" prop="name">
          <el-input
            v-model="detailform.name"
            placeholder="工艺名称"
            clearable
          />
        </el-form-item>
        <div class="desc_title">源机器人</div>
          <el-table
            ref="multipleTable"
            :data="tableData1"
            tooltip-effect="dark"
            row-key="id"
          >
            <el-table-column label="机器人编码">
              <template #default="scope">
                <el-form-item label="机器人编码" prop="robotSn">
                <el-select v-model="detailform.robotSn" placeholder="机器人编码" :remote-method="robotSnQuery" @change="(val)=>onRobotChange(val)" filterable remote clearable>
                  <el-option
                    v-for="(item) in robotSnList"
                    :key="item.robotId"
                    :label="item.robotId"
                    :value="item.robotId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="programName" label="控制柜编码">
              {{ detailform.cabinetSn }}
            </el-table-column>
            <el-table-column prop="programName" label="状态">
              {{ statusList[detailform.robotStatus] }}
            </el-table-column>
          </el-table>
        <div class="desc_title" v-if="!detailform.source" style="margin-top: 20px;">上传工艺程序</div>
        <el-form-item v-if="!detailform.source" label="工艺程序" prop="file">
          <Upload
            :file-list="fileList"
            :limit="1"
            :auto-upload="false"
            :on-change="onFileChange"
            :on-remove="onFileRemove"
          >
            <el-button :disabled="!!detailform.file" type="text">点击上传文件</el-button>
            <template #tip>
              <!-- <div class="el-upload__tip">数量1，格式为zip、rar、tar、gz</div> -->
              <div class="el-upload__tip">提示：请上传从 Coboπ 导出的程序文件（其他来源可能导致上传失败）</div>
            </template>
          </Upload>
        </el-form-item>
        <div class="desc_title" v-if="detailform.source" style="margin-top: 20px;">
          选择工艺程序
          <el-button :loading="loading" :disabled="detailform.robotSn == ''" type="primary" size="mini" @click="robotProgram(row)">刷新</el-button>
        </div>
        <el-form-item v-if="detailform.source" label="" label-width="0px" prop="selectedRow">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            row-key="id"
          >
            <el-table-column width="55">
              <template #default="scope">
                <el-radio v-model="detailform.selectedRow" :label="scope.$index">&nbsp;</el-radio>
              </template>
            </el-table-column>
            <el-table-column width="80"  label="序号">
              <template #default="scope">{{scope.$index + 1}}</template>
            </el-table-column>
            <el-table-column prop="fileName" label="程序名称"></el-table-column>
            <el-table-column prop="fileSize" label="程序大小"></el-table-column>
            <el-table-column prop="createTime" label="创建时间"></el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" type="primary" @click="editSubmit"
          >确认</el-button
        >
      </template>
  </el-dialog>
</template>

<script>
import { request } from '@/utils/request'
import { common } from '../../common/mixin'
import Table from '@/components/table/index'
import Filter from '@/components/table/filter'
import dayjs from 'dayjs'
import Upload from '@/components/upload'
import { ElMessage, ElMessageBox } from "element-plus";
import { reqCloneProgram } from "@/api/robot"
import { robotStatusMap } from "@/common/constant";
export default {
  components: {
    Table,
    Filter,
    Upload
  },
  mixins: [common],
  data () {
    const uploadStatus = [
      {
        label: '未开始',
        value: '0'
      },
      {
        label: '等待机器人响应',
        value: '1'
      },
      {
        label: '上传中',
        value: '2'
      },
      {
        label: '上传完成',
        value: '3'
      },
      {
        label: '上传失败',
        value: '4'
      }
    ]
    return {
      params: [
        {
          key: 'name',
          value: '',
          label: '工艺名称'
        },
        {
          key: 'robotSn',
          value: '',
          label: '机器人编号'
        },
        {
          key: 'cabinetSn',
          value: '',
          label: '控制柜编号'
        },
        {
          type: 'Select',
          key: 'uploadStatus',
          value: null,
          label: '上传状态',
          options: {
            data: uploadStatus,
            label: 'label',
            value: 'value'
          }
        },
        {
          type: 'Date',
          key: 'dateRange',
          value: [
            dayjs().subtract(7, 'day').format('YYYY-MM-DD') + ' 00:00:00',
            dayjs().format('YYYY-MM-DD') + ' 23:59:59'
          ],
          label: '上传日期',
          options: {
            type: 'datetimerange',
            format: 'YYYY-MM-DD HH:mm:ss',
            'value-format': 'YYYY-MM-DD HH:mm:ss',
            'range-separator': '至',
            'start-placeholder': '开始日期',
            'end-placeholder': '结束日期',
            'default-time': [
              new Date(2000, 1, 1, 0, 0, 0),
              new Date(2000, 1, 1, 23, 59, 59)
            ]
          }
        }
      ],
      query: {},
      page: 1,
      size: 10,
      total: 0,
      tableDataList: [],
      dialogFormVisible: false,
      detailform: {
        name: '',
        robotSn: '',
        cabinetSn: '',
        file: '',
        programName: '',
        source: 0,
        taskType: 0,
        selectedRow: null,
        robotStatus: null
      },
      detailformRules:{
        name: {
          required: true,
          message: '请输入工艺名称',
          trigger: 'change'
        },
        robotSn: {
          required: true,
          message: '请选择机器人编号',
          trigger: 'change'
        },
        file: {
          required: true,
          message: '请上传文件',
          trigger: 'change'
        },
        selectedRow: {
          required: true,
          message: '请选择工艺程序',
          trigger: 'change'
        }
      },
      fileList: [],
      uploadStatus: {0:'未开始',1:'等待机器人响应',2:'上传中',3:'上传完成',4:'上传失败'},
      tableData: [],
      tableData1: [{programName: ''},],
      robotSnList: [],
      statusList: robotStatusMap,
      timer: null,
      loading: false,
    }
  },
  created () {},
  mounted () {
    this.$refs.filterRef?.searchData()
  },
  onUnmounted() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  watch: {
    "dialogFormVisible": function(value, old) {
      clearInterval(this.timer);
      this.loading = false
    },
  },
  methods: {
    getData () {
      request('getCloneProgram', {
        ...this.query,
        page: this.page,
        size: this.size,
        startTime: this.query.dateRange[0],
        endTime: this.query.dateRange[1]
      })
        .then(data => {
          this.tableDataList = data.records
          this.total = parseInt(data.total)
        })
        .catch(error => {
          console.error(error)
        })
    },
    getDataMap () {
      try {
        request('getArea', {
          parentId: 1
        }).then(data => {
          this.provinceList = data
        })
        request('getRobotStatusMap').then(data => {
          this.robotStatusList = data
        })
        request('getIndustryList').then(data => {
          this.industryList = data
        })
      } catch (error) {
        console.log(error)
      }
    },
    searchData (data) {
      this.query = data
      this.getData()
    },
    updatePage (page, size) {
      this.page = page || 1
      this.size = size || 10
      this.getData()
    },
    handleClick (row) {
      this.$router.push({
        path: '/collect/healthScoreTrend',
        query: { robotSn: row.robotSn }
      })
    },
    addNewRobot () {

    },
    addProgramLib (type) {
      this.detailform.source = type
      this.dialogFormVisible = true
      this.detailform.name = ''
      this.detailform.robotSn = ''
      this.detailform.cabinetSn = ''
      this.detailform.selectedRow = ''
      this.detailform.programName = ''
      this.detailform.file = ''
      this.detailform.robotStatus = ''
      this.fileList = []
      this.tableData = []
      this.detailform.id = null
    },
    onFileChange (file) {
      let res = false
      for (let item of ['zip', 'rar', 'tar', 'gz']) {
        if (file.name.includes(item)) {
          res = true
          break
        }
      }
      if (!res) {
        this.fileList = []
        ElMessage({
          showClose: true,
          type: "warning",
          message: "只能上传zip、rar、tar、gz格式的文件"
        })
        return
      }
      this.detailform.file = file.raw
    },
    onFileRemove () {
      this.detailform.file = undefined
      this.detailform.fileSize = undefined
    },
    async editSubmit () {
      this.$refs.detailformRef && await this.$refs.detailformRef.validate()
      const data = new FormData()
      Object.keys(this.detailform).forEach((key) => {
        this.detailform[key] && data.append(key, this.detailform[key])
      })
        if(this.detailform.source == 1){
          this.detailform.programName = this.tableData[this.detailform.selectedRow].fileName
          this.detailform.fileSize = this.tableData[this.detailform.selectedRow].fileSize
          request('createUploadTask', this.detailform)
          .then(data => {
            this.dialogFormVisible = false
            this.getData();
          })
          .catch(error => {
            console.error(error)
          })
      }else{
        request('cloneProgramUpload', data)
        .then(data => {
          this.dialogFormVisible = false
          this.getData();
        })
        .catch(error => {
          console.error(error)
        })
      }
    },
    enableChange (val) {
      request("cloneProgramDisable", {
        isDisable: val.isDisable,
        id: val.id,
      })
        .then((data) => {
          this.getData();
        })
        .catch((error) => {
          this.getData();
          console.error(error);
        });
    },
    async handleOperate (row,type) {
      switch (type) {
        case 0: 
          this.detailform.id = row.id
          this.detailform.name = row.name
          this.detailform.robotSn = row.robotSn
          this.detailform.cabinetSn = row.cabinetSn
          this.detailform.file = row.file
          this.detailform.source = row.source
          this.detailform.robotStatus = row.robotStatus
          this.robotSnQuery(row.robotSn)
          this.robotProgram(row)
          if (row.filePath) {
            this.detailform.file = row.fileName
            this.detailform.fileSize = row.fileSize
            this.fileList = [{ name: row.fileName, url: row.filePath }]
          } else {
            this.detailform.file = ''
            this.fileList = []
          }
          this.dialogFormVisible = true
          break;
        case 1:  
          this.$router.push({
            path: '/process/cloningRecord',
            query: { programId: row.id }
          });
         break;
        case 2: 
          this.$router.push({
            path: '/process/cloningRecord',
            query: { programName: row.name, createTime: row.createTime }
          });
          break;
        case 3: 
        try {
          await ElMessageBox.confirm(
            '确定删除？',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
          )
          await reqCloneProgram(row.id)
          ElMessage({
            showClose: true,
            type: "success",
            message: "删除成功!"
          })
        this.getData()
      } catch (error) {
        console.error(error)
      }
        break;
      }
    },
    robotProgram (row) {
      if(this.detailform.robotSn == ''){
        return false
      }
      this.loading = true
      this.timer = setInterval(() => {
        request('getCloneRobotProgram', {robotSn: this.detailform.robotSn,})
        .then(data => {
          if(data.length !== 0){
            this.tableData = data
            this.detailform.selectedRow = this.tableData.findIndex(item => item.fileName == row.programName)
            clearInterval(this.timer);
            this.loading = false
          }
        })
        .catch(error => {
          clearInterval(this.timer);
          this.loading = false
          console.error(error)
        })
      }, 2000);
    },
    async robotSnQuery (name) {
      try {
        this.robotSnList = []
        const res = await request('getCollectRobot', {robotId: name})
        this.robotSnList = (res.records || []).slice(0, 20)
      } catch (error) {
        console.error(error)
      }
    },
    onRobotChange (val) {
      const currtRobot = this.robotSnList.filter(item=> item.robotId == val)[0]
      this.detailform.cabinetSn = currtRobot.cabinetId
      this.detailform.robotStatus = currtRobot.status
    },
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.progress-text {
  font-size: 12px;
}
.desc_title{
  position: relative;
  padding-left: 12px;
  margin-bottom: 20px;
  font-size: 18px;
  color: #1677ff;
}
.desc_title::before{
  position: absolute;
  content: ' ';
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 16px;
  background-color: #1677ff;
}
</style>

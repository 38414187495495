<template>
  <div class="page-container">
    <Filter :params="params.filter(item=> item.internalUser !== false)" :add="true" @search="searchData" @add="addNewRobot">
      <template #handle>
        <el-form-item>
          <el-button v-if="userInfo.internalUser && !standard" type="primary" plain @click="setOtaIsShow">显示OTA</el-button>
        </el-form-item>
      </template>
    </Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage" :select="!standard" :selectable="selectable" @select="handleSelect">
      <el-table-column prop="createTime" label="创建时间" />
      <el-table-column prop="robotId" label="机器人编码" />
      <el-table-column v-if="userInfo.internalUser" prop="robotIdOutStock" label="机器人编码(实际出库)" />
      <el-table-column prop="cabinet" label="控制柜编码" >
        <template #default="{ row }">
          <span>{{ row.cabNo }}</span>
          <!-- <el-button type="text" size="small" @click="bindCabinet" >关联控制柜</el-button> -->
        </template>
      </el-table-column>
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="modelName" label="规格型号" />
      <!-- <el-table-column prop="companyName" label="公司名称" /> -->
      <!-- <el-table-column prop="industry" label="公司行业" /> -->
      <!-- <el-table-column prop="robotName" label="机器人名称" width="130" /> -->
      <!-- <el-table-column label="机器人位置">
          <template #default="scope">
            {{ scope.row.cityName + scope.row.areaName + scope.row.location }}
          </template>
        </el-table-column> -->
      <!-- <el-table-column prop="province" label="省"/>
        <el-table-column prop="city" label="市"/>
        <el-table-column prop="area" label="区"/> -->
      <el-table-column v-if="userInfo.internalUser" label="是否接入OTA">
        <template #default="scope">
          {{ scope.row.ota ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column v-if="userInfo.internalUser && !standard" label="前端是否展示">
        <template #default="scope">
          {{ scope.row.isShow == 1 ? "是" : "否" }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="produceTime" label="生产时间" width="160" /> -->
      <el-table-column prop="ledgerBatch" label="出库时间" />
      <el-table-column prop="customer" label="客户" />
      <el-table-column v-if="userInfo.internalUser" prop="remark" label="备注" />
      <el-table-column label="操作" width="180">
        <template #default="{ row }">
          <el-button type="primary" plain size="mini" @click="editRobot(row)">编辑</el-button>
          <el-button v-if="row.isShow == 1 && !standard" type="primary" plain size="mini" @click="cancelOtaIsShow(row)">隐藏OTA</el-button>
        </template>
      </el-table-column>
    </Table>
    <el-dialog v-model="dialogFormVisible" top="5vh" destroy-on-close :modal="true" :append-to-body="true">
      <el-form ref="formRef" class="dialog-form" :model="form" :rules="formRules" label-width="120px">
        <el-form-item label="机器人编码" prop="robotId">
          <el-input v-model="form.robotId"></el-input>
        </el-form-item>
        <el-form-item label="控制柜编码" prop="cabNo">
          <el-select v-model="form.cabNo" :disabled="!!form.ota" placeholder="控制柜编码" :remote-method="cabinetQuery" filterable remote clearable>
            <el-option
              v-for="(item) in cabinetList"
              :key="item.cabinetId"
              :label="item.cabinetId"
              :value="item.cabinetId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品名称">
          <el-input v-model="form.robotName" placeholder="产品名称" />
        </el-form-item>
        <!-- <el-form-item label="规格型号" prop="modelName">
          <el-input v-model="form.modelName" placeholder="规格型号" />
        </el-form-item> -->
        <!-- <el-form-item label="公司名称">
          <el-input v-model="form.companyName" placeholder="公司名称" />
        </el-form-item> -->
        <!-- <el-form-item label="机器人名称">
          <el-input v-model="form.robotName" placeholder="机器人名称" />
        </el-form-item> -->
        <el-form-item label="机器人位置">
          <el-row>
            <el-col :span="6">
              <el-select v-model="form.country" placeholder="国家">
                <el-option
                  v-for="item in countryList"
                  :key="item.regionId"
                  :label="item.nameCn"
                  :value="item.regionId"
                />
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-select v-model="form.province" placeholder="省">
                <el-option
                  v-for="item in provinceList"
                  :key="item.regionId"
                  :label="item.nameCn"
                  :value="item.regionId"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-select v-model="form.city" placeholder="市">
                <el-option
                  v-for="item in formCityList"
                  :key="item.regionId"
                  :label="item.nameCn"
                  :value="item.regionId"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-select v-model="form.area" placeholder="区">
                <el-option
                  v-for="item in formAreaList"
                  :key="item.regionId"
                  :label="item.nameCn"
                  :value="item.regionId"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-input v-model="form.location" placeholder="机器人详细地址" />
        </el-form-item>
        <!-- <el-form-item label="是否接入OTA" prop="ota">
          <el-select v-model="form.ota" placeholder="是否接入OTA">
            <el-option
              v-for="item in options"
              :key="item.name"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item> -->
        <el-form-item label="机器人生产时间">
          <el-date-picker
            v-model="form.produceTime"
            type="datetime"
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            placeholder="机器人生产时间"
          />
        </el-form-item>
        <el-form-item label="机器人出库时间">
          <el-date-picker
            v-model="form.ledgerBatch"
            type="datetime"
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            placeholder="机器人出库时间"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" size="small" @click="onSubmit">确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "../../common/mixin";
import { formatTime } from "../../common/utils";
import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
import { ElMessage } from "element-plus";
import { standard } from '@/common/constant';

export default {
  components: {
    Table,
    Filter,
  },
  mixins: [common],
  data() {
    return {
      dialogFormVisible: false,
      formRef: null,
      form: {
        id: '',
        robotId: '',
        cabNo: '',
        robotName: '',
        // productName: '',
        // modelName: '',
        country: 80,
        province: '',
        city: '',
        area: '',
        location: '',
        ota: 0,
        produceTime: '',
        ledgerBatch: '',
      },
      formRules: {
        robotId: {
          required: true,
          message: '请输入机器人编码',
          trigger: 'blur',
        },
        // modelName: {
        //   required: true,
        //   message: '请输入规格型号',
        //   trigger: 'blur',
        // },
        // ota: {
        //   required: true,
        //   message: '请选择是否接入OTA',
        //   trigger: 'change'
        // }
      },
      cabinetList: [],
      params: [
        {
          key: "robotId",
          value: '',
          label: "机器人编码",
        },
        {
          key: "cabNo",
          value: '',
          label: "控制柜编码",
        },
        {
          type: "Select",
          key: "ota",
          value: '',
          label: "是否接入OTA",
          options: {
            data: [{ name: '是', value: 1 }, { name: '否', value: 0 }],
            label: "name",
            value: "value"
          },
          internalUser: JSON.parse(window.localStorage.getItem('userInfo')).internalUser
        },
        {
          type: "Select",
          key: "isShow",
          value: '',
          label: "前端是否展示",
          options: {
            data: [{ name: '是', value: 1 }, { name: '否', value: 2 }],
            label: "name",
            value: "value"
          },
          internalUser: JSON.parse(window.localStorage.getItem('userInfo')).internalUser && !standard
        },
        {
          key: "customer",
          value: '',
          label: "客户名称",
        },
        {
          type: "Date",
          key: "dateRange",
          value: [],
          label: "出库时间",
          options: {
            "type": "datetimerange",
            "format": "YYYY-MM-DD HH:mm:ss",
            "value-format": "YYYY-MM-DD HH:mm:ss",
            "range-separator": "至",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期",
            "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]
          }
        },
      ],
      query: {},
      page: 1,
      size: 10,
      total: 0,
      tableDataList: [],
      countryList: [
        {
          nameCn: "中国",
          nameEn: "China",
          regionId: '80',
        },
      ],
      provinceList: [],
      cityList: [],
      areaList: [],
      formCityList: [],
      formAreaList: [],
      robotStatusList: [], // 机器人状态下拉列表
      industryList: [], // 行业下拉列表
      options: [
        {
          id: true,
          name: "是",
        },
        {
          id: false,
          name: "否",
        },
      ],
      batchDataList: [], // 表格多选
      userInfo: {},
      standard: standard
    };
  },
  watch: {
    "query.province": function(value, old) {
      if (value && value !== old) {
        console.log("province", value);
        request("getArea", {
          parentId: Number(value),
        }).then((data) => {
          this.cityList = data;
        });
      }
    },
    "query.city": function(value, old) {
      if (value && value !== old) {
        request("getArea", {
          parentId: Number(value),
        }).then((data) => {
          this.areaList = data;
        });
      }
    },
    "form.province": function(value, old) {
      if (value && value !== old) {
        console.log("province", value);
        request("getArea", {
          parentId: Number(value),
        }).then((data) => {
          this.formCityList = data;
        });
      }
    },
    "form.city": function(value, old) {
      if (value && value !== old) {
        request("getArea", {
          parentId: Number(value),
        }).then((data) => {
          this.formAreaList = data;
        });
      }
    },
  },
  created() {
    this.getData();
    this.getDataMap();
    try {
      const userInfo = window.localStorage.getItem('userInfo')
      if (userInfo) {
        this.userInfo = JSON.parse(userInfo) || {}
      }
    } catch (error) {
      console.error(error)
    }
  },
  mounted() {
    // this.tableDataList = robotList({}).then(data => {
    //   this.tableDataList = data
    // })
    // this.$store.dispatch('status/robot', {})
    // .then(data => {
    //   console.log(data)
    // })
    // robotList({}).then(data => {
    //   this.tableDataList = data
    // })
  },
  methods: {
    getData() {
      request("getRobotList", {
        ...this.query,
        page: this.page,
        size: this.size,
      })
        .then((data) => {
          // this.tableDataList = data || [];
          // this.total = data.length;
          this.tableDataList = data.records;
          this.total = data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDataMap() {
      try {
        request("getArea", {
          parentId: 1,
        }).then((data) => {
          this.provinceList = data;
        });
        request("getRobotStatusMap").then((data) => {
          this.robotStatusList = data;
        });
        request("getIndustryList").then((data) => {
          this.industryList = data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    searchData(data) {
      this.query = data;
      this.getData();
    },
    updatePage(page, size) {
      this.page = page || 1;
      this.size = size || 10;
      this.getData();
    },
    selectable (row) {
      if (row.isShow == 1) {
        return false
      } else {
        return true
      }
    },
    handleSelect(data) {
      this.batchDataList = data.map((item)=>{
        return item.id
      })
    },
    addNewRobot() {
      for (let key of Object.keys(this.form)) {
        this.form[key] = '';
      }
      this.dialogFormVisible = true;
      console.log(this.form);
    },
    editRobot(data) {
      for (let key of Object.keys(this.form)) {
        if (typeof data[key] != 'undefined') {
          this.form[key] = data[key];
        } else {
          this.form[key] = '';
        }
      }
      this.form.id = data.id;
      this.dialogFormVisible = true;
      this.cabinetQuery()
      console.log(this.form);
      return;
    },
    async onSubmit() {
      try {
        this.$refs.formRef && await this.$refs.formRef.validate()
        const api =  this.form.id ? 'updateRobot' : 'addRobot'
        await request(api, this.form)
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
        this.dialogFormVisible = false
        this.getData()
      } catch (error) {
        console.error(error)
      }
    },

    onCancel() {
      this.dialogFormVisible = false;
    },
    async setOtaIsShow() {
      try {
        await request('setOtaIsShow', {
          ids: this.batchDataList
        })
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
        this.getData()
      } catch (error) {
        console.error(error)
      }
    },
    async cancelOtaIsShow(row) {
      try {
        await request('cancelOtaIsShow', {
          id: row.id
        })
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
        this.getData()
      } catch (error) {
        console.error(error)
      }
    },
    async cabinetQuery (name) {
      try {
        this.cabinetList = []
        const res = await request('getAssociationCabinet', {
          id: this.form.id,
          cabinetId: name
        })
        this.cabinetList = (res || []).slice(0, 20)
      } catch (error) {
        console.error(error)
      }
    },
    dateChange() {
      console.log("dateChange: ", this.dateRange);
    },
    formatproduceTime(date) {
      console.log(date);
      if (date) {
        this.form.produceTime = formatTime(date);
      }
    },
    formatledgerBatch(date) {
      console.log(date);
      if (date) {
        this.form.ledgerBatch = formatTime(date);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>
